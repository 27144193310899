/* Reset default margins and paddings for all elements, use border-box for box-sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;  /* Ensure the html and body take up the full height of the viewport */
  overflow: auto;
  background-color: #faf0e6;
}

/* New rule: #root acts as the main flex container */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

/* Updated .App to be a flex container */
.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;     /* Make the App take up the remaining space */
  text-align: center;
}

/* New rule: Add .App-content class to flex-grow and occupy the remaining space */
.App-content {
  flex-grow: 1;  /* Ensure .App-content takes up the remaining height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Header and footer layouts */
.App-header, .App-footer {
  width: 100%; /* Ensure both span the full width of the viewport */
  padding-left: 1rem; /* Add equal padding to left */
  padding-right: 1rem; /* Add equal padding to right */
  box-sizing: border-box; /* Include padding in the element's total width */
}

.App-header {
  flex-shrink: 0; /* Prevent the header from shrinking */
  padding: 1rem; /* Padding for content inside the header */
  background: #282c34; /* Background color for the header */
  color: white; /* Text color */
  display: flex; /* Use flexbox for horizontal alignment */
  justify-content: space-between; /* Space out children elements */
  align-items: center; /* Center children vertically */
  height: 13%; /* Adjust this according to your needs */
  position: fixed; /* Fix position at top */
  top: 0; /* Align to top of the viewport */
  left: 0; /* Align to left edge of the viewport */
  right: 0; /* Align to right edge of the viewport */
  z-index: 1000; /* Ensure the header is above other content */
}

.App-footer {
  position: relative;
  width: 100%;
  flex-shrink: 0; /* Prevent the footer from shrinking */
  background: #282c34; /* Background color for the footer */
  color: white; /* Text color */
  display: flex; /* Use flexbox for horizontal alignment */
  justify-content: center; /* Center the footer content horizontally */
  align-items: center; /* Center children vertically */
  height: 10vh; /* Adjust this according to your needs */
  bottom: 0; /* Align to bottom of the viewport */
  left: 0; /* Align to left edge of the viewport */
  right: 0; /* Align to right edge of the viewport */
  z-index: 1000; /* Ensure the footer is above other content */
}

.App-header img {
  width: 60%;
  height: auto;
}

/* Style for footer icons */
.footer-icons a {
  color: white;                 /* Set icon color to white */
  text-decoration: none;          /* Remove underline from icons */
  margin-left: 15px;              /* Add left margin */
  margin-right: 15px;             /* Add right margin */
}

.footer-icon {
  font-size: 2rem;
}

.body-margin {
  margin-top: 25vh;
  margin-bottom: 12vh;
}

/* Header right navigation styling */
.header-right nav ul {
  list-style: none;               /* Remove bullet points from list */
  display: flex;                  /* Use flexbox for horizontal list items */
  margin-right: 45px;             /* Add right margin */
  padding: 0;                     /* Remove padding */
}

/* Navigation list items and links styling */
.header-right nav ul li {
  margin-left: 1rem;              /* Add left margin between list items */
  font-size: 1.5em;               /* Set font size */
}

.header-right nav ul li a {
  color: white;                   /* Set text color to white */
}

.header-right a { 
  text-decoration: none; /* Remove underline from links */
}

.header-right a:hover, .header-right a:focus, .header-right a:active {
  text-decoration: none; /* Ensure underline is removed in different states */
}

/* Album list and Home page image wrapper styles */
.cover-list, .album-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Define two columns per row */
  grid-column-gap: 1rem; /* Decrease horizontal gap between grid items */
  justify-items: start; /* Align items to the left */
  width: 80%;
  height: auto;
  margin: 0 auto;
}

.cover, .album {
  padding: 0.7rem;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 10px;
}

.cover img, .album img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.album-date {
  margin-top: 0.5rem;
  font-family: 'Arial, sans-serif'; /* Set the font family */
  font-size: 1.8em; /* Set the font size */
  color: #000000; /* Set the text color */
  /* font-style: italic; Set the font style to italic */
  font-weight: bold; /* Set the font weight */
  text-align: left;
  margin-left: 2rem;
}

.album-description {
  margin-top: 0.5rem;
  font-family: 'Arial, sans-serif'; /* Set the font family */
  font-size: 1.8em; /* Set the font size */
  color: #000000; /* Set the text color */
  /* font-style: italic; Set the font style to italic */
  font-weight: bold; /* Set the font weight */
  text-align: left;
  margin-left: 2rem;
}

.album-link {
  text-decoration: none; /* Removes underline from the link */
}

.about-grid {   /* everything for about page  */
  display: grid;
  width: 60%;
  height: auto;
  margin: 0 auto;
  justify-content: center;        /* Center the grid items horizontally */
  align-items: center;            /* Center the grid items vertically */
  /* font */
  font-weight: bold;
  font-size: 23px;
  line-height: 2;
}

.about-grid > p {
  margin-top: 30px;
  margin-bottom: 30px;
}

.about-grid img {
  width: 85%;
  height: auto;
  border-radius: 8px;
}

.mission {  /* everything for mission page  */
  display: grid;
  grid-template-columns: 42% 17%;
  gap: 10px;
  padding: 10px;
  justify-content: space-evenly;
}

.mission > *:nth-child(odd) {
  margin-right: -7rem; /* Reduce margin-right for items on the left side (1, 3, 5, ...) */
  /* background-color: rgba(212, 212, 212, 0.8); */
  text-align: start;
  padding: 20px 0;
  font-size: 20px;
  border-radius: 8px;
  line-height: 2;
}

.mission > *:nth-child(odd) p:not(:last-child) {
  text-indent: 8%;
}

.mission > *:nth-child(odd) p:after {
  content: "";
  display: block;
  height: 1em; /* Adjust the height as needed for your line break */
}

.mission > *:nth-child(odd) p:last-child {
  font-style: italic;
}

.mission > *:nth-child(even) {
  margin-left: -7rem; /* Reduce margin-right for items on the left side (1, 3, 5, ...) */
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
  display: flex; /* Use flexbox for centering */
  flex-direction: column; /* Ensure items are stacked vertically */
  align-items: center; /* Center items horizontally */
  text-align: center;
  width: 100%; /* Take up full width of the parent container */
}

.mission > *:nth-child(even) div {
  margin-top: 30px;
  margin-bottom: 30px;
}

.gofundme-button {
  background-color: #00b140; /* GoFundMe green */
  color: rgb(255, 255, 255);
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 15px;
  border-radius: 50px;
}

.gofundme-button:hover {
  background-color: #009933;
}

.donation {
  margin-top: 30px;
  margin-bottom: 30px;
  /* text */
  font-style: italic;
  font-weight: bold;
  line-height: 1;
}

.donation > button {
  margin-top: 15px;
}

.contact {    /* everything for contact page  */
  display: grid;
  width: 45%;
  height: auto;
  margin: 0 auto;
  justify-content: center;        /* Center the grid items horizontally */
  text-align: start;

  margin-top: 70px;
  margin-bottom: 70px;
  /* font */
  font-size: 22px;
  line-height: 2;
}

.contact > h1 {
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  font-family: "Lucida Console", Courier, monospace;
  font-size: 50px;
}

.alert {
  font-size: 17px;
  font-style: italic;
  line-height: 1;
}

.no-line-height {
  line-height: 1;
  margin-top: -10px;
}

.image-gallery {
  column-count: 4;
  column-gap: 1rem; /* Adjust gap between columns */
  gap: 1rem;
  padding: 9rem; /* Adjust padding as needed */
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.image-gallery img {
  width: 90%;
  margin-bottom: 3rem; /* Adjust gap between images vertically */
  display: block;
  border-radius: 8px; /* Optional styling */
  object-fit: cover; /* Maintain aspect ratio */
  break-inside: avoid;
}

/* For Responsive Layouts */

/* Small screen styles */
.header-center {
  display: none;
  flex-direction: column;
  align-items: center;
  position: relative;
}

@media only screen and (orientation: landscape) {
  .App {
    width: 100vw; /* Force App to take the full viewport width */
    height: 100vh; /* Force App to take the full viewport height */
  }
}

@media (max-width: 1290px) {
  .header-left, .header-right {
    display: none; /* Hide header-left and header-right on small screens */
  }

  .header-center {
    display: grid; /* Show header-center on small screens */
    grid-template-columns: 10% 85%;
    flex-direction: row; /* Arrange children in a row */
    align-items: center; /* Center align items vertically */
    justify-content: center; /* Center align items horizontally */
    width: 100%; /* Take full width of the parent */
  }

  .header-center img {
    height: 10vh; /* Fill the height of the header */
    width: auto; /* Maintain aspect ratio */
    max-height: 100%; /* Prevent it from exceeding the header's height */
    margin-right: 10%;
  }

  .App-header {
    justify-content: center; /* Center the .App-header content */
    height: 12%;
  }

  .App-footer {
    height: 8vh;
  }

  /* Styles for dropdown toggle button */
  .dropdown-toggle {
    background: none; /* No background for the button */
    border: none; /* No border for the button */
    color: white; /* White color for the button text */
    font-size: 1.5em; /* Large font size for the button text */
  }

  /* Styles for the dropdown menu */
  .dropdown-menu {
    position: fixed; /* Position absolutely relative to nearest positioned ancestor */
    top: 10%; /* Position dropdown menu below its parent */
    left: 0; /* Align dropdown to the left */
    right: 0; /* Stretch dropdown to the right */
    background: #282c34; /* Dark background color for the dropdown */
    display: flex; /* Use flexbox for dropdown menu layout */
    flex-direction: column; /* Arrange menu items in a column */
    align-items: center; /* Center align menu items horizontally */
    overflow: hidden; /* Hide overflow for smooth animation */
    max-height: 0; /* Initially, collapse the menu */
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out; /* Animate max-height and opacity */
    opacity: 0; /* Initially, make menu items invisible */
    z-index: 999; /* Ensure the dropdown appears above other content */
  }

  /* Show the dropdown menu when the 'show' class is added */
  .dropdown-menu.show {
    max-height: 300px; /* Adjust this value to fit your dropdown content */
    opacity: 1; /* Make the menu items visible */
  }

  /* Keyframe animation for individual dropdown items */
  @keyframes dropdown {
    0% {
      opacity: 0; /* Fully transparent */
      transform: translateY(-10px); /* Slightly above its position */
    }
    100% {
      opacity: 1; /* Fully opaque */
      transform: translateY(0); /* At its final position */
    }
  }

  /* Styles for the list inside the dropdown menu */
  .dropdown-menu ul {
    list-style: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }

  /* Styles for each list item inside the dropdown menu */
  .dropdown-menu ul li {
    margin: 1rem 0; /* Add vertical spacing between list items */
    opacity: 0; /* Initially hide the items */
  }

  /* Apply the animation only when the menu is shown */
  .dropdown-menu.show ul li {
    animation: dropdown 0.5s ease forwards; /* Apply the dropdown animation */
  }

  /* Applying a delay to each list item for staggered effect */
  .dropdown-menu.show ul li:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dropdown-menu.show ul li:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .dropdown-menu.show ul li:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .dropdown-menu.show ul li:nth-child(4) {
    animation-delay: 0.3s;
  }

  /* Add additional nth-child selectors as needed for more list items */

  /* Styles for links inside the dropdown menu */
  .dropdown-menu ul li a {
    color: white; /* White color for the links */
    font-size: 1.5em; /* Large font size for the links */
    text-decoration: none; /* Remove underline from links */
  }

  .album-description {
    font-size: 20px;
  }

  .image-gallery {
    column-count: 3;
    column-gap: 1rem; /* Adjust gap between columns */
    gap: 1rem;
    padding: 4rem; /* Adjust padding as needed */
    margin-left: 2rem;
  }
}

@media (max-width: 900px) {
  .album-list {
    width: 85%;
  }

  .album-description {
    font-size: large;
  }
}

/* Media query for small screens */
@media (max-width: 768px) {
  .App-header {
    height: 12%;
  }

  .album-list {
    width: 85%;
  }

  .image-gallery {
    column-count: 2;
    column-gap: 1rem; /* Adjust gap between columns */
    gap: 1rem;
    padding: 2rem; /* Adjust padding as needed */
    margin-top: 1vh;
    margin-left: 2rem;
  }

  .mission {
    display: grid;
    grid-template-columns: 40%;
  }

  .mission > *:nth-child(odd) {
    font-size: 15px;
    border-radius: 8px;
    line-height: 2;
  }

  .statement {
    display: block;
    margin-left: -7rem;
  }

  .about-grid {   /* everything for about page  */
    width: 80%;
    height: auto;
    margin: 0 auto;
    /* font */
    font-weight: bold;
    font-size: 15px;
    line-height: 1.5;
  }
  
  .about-grid  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .about-grid img {
    width: 85%;
    height: auto;
    border-radius: 8px;
  }

  .contact {    /* everything for contact page  */
    display: grid;
    width: 80%;
    height: auto;
    margin: 0 auto;
    justify-content: center;        /* Center the grid items horizontally */
    text-align: start;
  
    margin-top: 70px;
    margin-bottom: 70px;
    /* font */
    font-size: 15px;
    line-height: 2;
  }
  
  .contact > h1 {
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
    font-family: "Lucida Console", Courier, monospace;
    font-size: 50px;
  }

  .contact > p {
    margin-bottom: 1rem;
  }
  
  .alert {
    font-size: 11px;
    font-style: italic;
  }
}

/* For Cover and Album list arrangement */
@media (max-width: 710px) {
  .album-description {
    font-size: medium;
  }
}

@media (max-width: 520px) {
  .album-list {
    grid-template-columns: 1fr; /* Set to one column */
  }

  .image-gallery {
    column-count: 1;
    column-gap: 1rem; /* Adjust gap between columns */
    gap: 1rem;
    padding: 3rem; /* Adjust padding as needed */
  }
}

@media (max-width: 600px) {
  .album-description {
    font-size: medium;
  }

  .mission {
    display: grid;
    grid-template-columns: 45%;
  }

  .mission > *:nth-child(odd) {
    text-align: start;
    padding: 20px 0;
    font-size: 15px;
    border-radius: 8px;
    line-height: 1;
  }

  .mission > *:nth-child(odd) h1 {
    line-height: 2;
  }

  .statement {
    display: block;
    margin-left: -6.5rem;
  }
}

@media (max-width: 430px) {
  .App-header {
    height: 11%;
  }

  .dropdown-menu {
      top: 11%;
  }

  .App-footer {
    height: 7vh;
  }

  .header-center img {
    height: 8vh; /* Fill the height of the header */
    width: auto; /* Maintain aspect ratio */
  }

  .footer-icon {
    font-size: 1.5rem;
  }  

  .body-margin {
    margin-top: 19vh;
    margin-bottom: 8vh;
  }

  .dropdown-toggle {
    font-size: 1.3em; /* Large font size for the button text */
  }
}